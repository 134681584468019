html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

h1 {
  margin: 0;
  padding: 0;
}

h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #444444;
}

h4 {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

h5 {
  font-size: 18px;
  color: #555555;
  font-family: "DM Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 1.2;
}

p {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #6e7191;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #222222;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 500;
}
a:hover, a:focus {
  color: #a85cf9;
}

.backgroundContainer {
  background-color: #f7f7fb;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
nav li {
  margin: 0 10px;
}
nav a {
  position: relative;
}

.navLink {
  padding: 10px;
}
.navLink:hover, .navLink:focus, .navLink:active {
  color: #a85cf9;
}
.navLink:hover::before, .navLink:focus::before, .navLink:active::before {
  visibility: visible;
}
.navLink::before {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 80%;
  background-color: #a85cf9;
  visibility: hidden;
}

.ContactLink {
  background: -webkit-gradient(linear, left top, right top, from(#6385ff), to(#6c63ff));
  background: linear-gradient(90deg, #6385ff 0%, #6c63ff 100%);
  color: #fff;
  border: 1px solid #0d6efd;
  border-radius: 60px;
  padding: 10px;
}
.ContactLink:hover, .ContactLink:focus, .ContactLink:active {
  color: #222222;
}

.mobileMenuBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  visibility: hidden;
}
.mobileMenuBtn:hover, .mobileMenuBtn:focus, .mobileMenuBtn:active {
  color: #a85cf9;
}

.homeBody {
  background-repeat: no-repeat;
  background-size: cover;
}

.heroSection {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.heroSection h2 {
  margin: 20px 0;
  font-size: 80px;
  font-weight: 700;
  line-height: 56px;
  color: #170f49;
  font-family: "Dm Sans", sans-serif;
}

.headingContainer {
  margin-top: 25%;
}

.imgContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.whatIsVizplay,
.about {
  background-color: #fff;
}

.about {
  padding: 100px 0;
}

.vizContainer {
  text-align: center;
  padding: 80px 0 50px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.vizContainer h2 {
  font-family: "DM Sans", sans-serif;
  color: #170f49;
  font-size: 50px;
  font-weight: 700;
  margin: 10px 0;
}
.vizContainer p {
  width: 50%;
  margin: 15px auto 0 auto;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6e7191;
}

.cardContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 50px 0;
}

.card {
  width: 30%;
  height: 402px;
  padding: 30px;
  margin: 15px 0;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
          box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card h4 {
  text-align: center;
  margin: 10px 0;
}
.card p {
  font-size: 15px;
  line-height: 28px;
}

.aboutContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 20px 0;
}
.aboutContainer .imgContainer {
  padding: 25px;
  border-radius: 25px;
  background-color: #fff;
}

.aboutTextContainer {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 30px;
}

.changeOrder {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.commentContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 80px;
}

.comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 30px;
  width: 30%;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
}
.comment .starContainer {
  color: rgb(108, 99, 255);
  font-size: 14px;
  line-height: 26px;
}

.commentHeadingContainer {
  text-align: center;
}

.articleLink {
  border: 2px solid #6c63ff;
  width: 175px;
  text-align: center;
  padding: 18px 24px;
  border-radius: 10px;
}
.articleLink:hover, .articleLink:focus {
  color: #fff;
  background-color: #6c63ff;
}

.footerContainer {
  padding: 50px 0;
  background-color: #f7f7fb;
}
.footerContainer p {
  font-size: 14px;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.social {
  font-size: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#6385ff), to(#6c63ff));
  background: linear-gradient(90deg, #6385ff 0%, #6c63ff 100%);
  color: #fff;
  padding: 10px;
}

.socialContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.socialDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  width: 25%;
}

.siteMapDiv {
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.siteMapDiv li {
  margin: 10px 0;
}
.siteMapDiv a {
  color: #6e7191;
  font-size: 14px;
}
.siteMapDiv a:hover, .siteMapDiv a:focus {
  color: #a85cf9;
}

.newsLetterDiv {
  width: 50%;
  text-align: center;
}

.subscribeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.subscribeForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 0;
  -webkit-box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.subscribeForm input {
  width: 80%;
  border: none;
}
.subscribeForm button {
  width: 20%;
  cursor: pointer;
  background: -webkit-gradient(linear, left top, right top, from(#6385ff), to(#6c63ff));
  background: linear-gradient(90deg, #6385ff 0%, #6c63ff 100%);
  color: white;
  font-size: 16px;
  padding: 10px 5px;
  border: none;
  border-radius: 0 4px 4px 0;
}

.creditsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 15px 0;
  border-top: 1px solid #c5c8ce;
  background-color: #f7f7fb;
}

.copyright {
  margin-right: 20px;
}

.credits {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.credits p {
  margin: 0 5px 0 0;
  color: #222222;
}
.credits ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.credits li {
  margin: 0 5px;
}
.credits a {
  padding: 0 5px;
  position: relative;
  color: #a85cf9;
  font-weight: 400;
}
.credits a::before {
  content: "|";
  position: absolute;
  left: -5px;
}
.credits a:hover, .credits a:focus {
  color: #6385ff;
}

.legalBody {
  background-color: #f7f7fa;
  padding-top: 80px;
}
.legalBody h2 {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 54px;
  line-height: 66px;
  text-align: center;
  color: #211f54;
  padding: 50px 0;
}

.legalBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #fff;
  border: 1px solid #eff0f7;
  -webkit-box-shadow: 0px 37px 114px rgba(32, 36, 138, 0.15);
          box-shadow: 0px 37px 114px rgba(32, 36, 138, 0.15);
  border-radius: 24px;
  padding: 64px 72px;
}

.legalHeading {
  color: #211f54;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.schematicBody {
  background-position: center right;
  min-height: 980px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
}

.schematicContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
  width: 728px;
  min-height: 550px;
  -webkit-box-shadow: 0px 14px 42px rgba(20, 20, 43, 0.14);
          box-shadow: 0px 14px 42px rgba(20, 20, 43, 0.14);
  border-radius: 24px;
  padding: 30px;
  margin-top: 5%;
  margin-left: 15%;
}

.schematic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.schematic ul {
  list-style: disc;
}
.schematic .textContainer {
  width: 50%;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.schematic .imgContainer {
  width: 45%;
}
.schematic h2 {
  color: #555555;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}

.howToPlaySection {
  background-color: #f7f7fb;
}

.howToPlayContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 100px;
}
.howToPlayContainer .imgContainer {
  width: 45%;
}

.howToPlayTextContainer {
  width: 45%;
  margin-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.schematicH3 {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 46px;
  color: #170f49;
  margin: 5px 0;
}

.instructions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 0;
}
.instructions .imgContainer {
  width: 20%;
}
.instructions .howToPlay {
  width: 80%;
}

.keyFeaturesSection {
  background-color: #fefefe;
  padding-bottom: 50px;
  padding-top: 100px;
  text-align: center;
}
.keyFeaturesSection .cardContainer {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.keyFeaturesSection .card {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.faqLink {
  background-color: #0d6efd;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px 0;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  margin: 20px 0;
}
.faqLink:hover, .faqLink:focus {
  color: #fff;
  background-color: #0b5ed7;
}

.faqContainer {
  background-color: #f7f7fb;
  padding: 50px 0 70px 0;
}

.collapse {
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin: 20px 0;
}
.collapse button {
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: start;
  cursor: pointer;
}
.collapse h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: #211f54;
}

.content {
  background-color: #fff;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
  padding: 0 30px;
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #6e7191;
}

.pointDown {
  -webkit-transform: rotate(0);
          transform: rotate(0);
  margin-right: 20px;
}

.pointUp {
  margin-right: 20px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faqSection .headingContainer {
  margin: 50px 0;
  text-align: center;
}
.faqSection h2 {
  margin: 0;
  font-size: 50px;
  font-weight: 600;
  color: #170f49;
  font-family: "DM Sans", sans-serif;
}

.developerBody {
  min-height: 980px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.developerTitleContainer h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  width: 40%;
}
.developerTitleContainer p {
  color: #fff;
}

.buildGamesSection,
.tools {
  background-color: #f7f7fb;
  padding: 100px 0;
}

.buildGamesContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.buildGames {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.buildGames h3 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  font-family: "DM Sans", sans-serif;
  width: 65%;
  margin-top: 0;
}
.buildGames p {
  width: 65%;
}

.tools {
  text-align: center;
}

.toolsContainer {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.toolsContainer .imgContainer {
  width: 20%;
  margin: 20px 0;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.toolsContainer .imgContainer:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.gamesMain {
  margin-top: 75px;
  padding-top: 80px;
  background-color: #f7f7fb;
}

.videoContainer {
  margin: 0 auto;
  width: 100%;
  padding: 50px;
}

.displayGames {
  padding: 100px 0;
}

.filterContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.filterList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.filterBtn {
  padding: 5px 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: "Kumbh Sans", sans-serif;
  letter-spacing: 1.5px;
  color: #444444;
  text-transform: uppercase;
}
.filterBtn:hover, .filterBtn:focus, .filterBtn:active {
  color: #a85cf9;
}

.gamesHeading {
  text-align: center;
  color: #170f49;
  font-size: 50px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.gameListContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.gameListContainer h3 {
  margin: 10px 0 0 0;
}
.gameListContainer p {
  margin: 10px 0;
}
.gameListContainer li {
  width: 48%;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contactBody {
  margin-top: 80px;
}

.wantToKnow {
  min-height: 30vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.formSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 50px 0;
}
.formSection .imgContainer {
  width: 48%;
}
.formSection .formContainer {
  width: 48%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
          box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
  border-radius: 5px;
  padding: 30px;
}

.contactUsForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding-top: 30px;
}

textarea {
  resize: none;
  height: 50%;
  padding: 15px;
  border: 1px solid #c5c8ce;
  border-radius: 5px;
  margin-bottom: 10px;
}

.contactInput {
  height: 20%;
  padding: 2px 15px;
  border: 1px solid #c5c8ce;
  border-radius: 5px;
  margin-bottom: 10px;
}

.submitBtn {
  height: 20%;
  width: 30%;
  -ms-flex-item-align: center;
      align-self: center;
  cursor: pointer;
  background-color: #a85cf9;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 20px 0;
}
.submitBtn:hover {
  background-color: #6385ff;
}

.resultBody {
  background-color: #f7f7fb;
  padding: 10px;
  border: 3px solid #0d6efd;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.hideBody {
  background-color: transparent;
  padding: 10px;
  border: 3px solid transparent;
  border-radius: 5px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.blogFormContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 0;
  min-height: 70vh;
}

.blogForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 80%;
  height: 60vh;
}
.blogForm .blogHeading {
  padding: 5px;
  padding-left: 15px;
  height: 10%;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #c5c8ce;
}
.blogForm .blogContent {
  height: 70%;
  margin: 0 10px;
}
.blogForm .blogBtn {
  padding: 10px;
  height: 10%;
  margin: 10px 0;
}

.schematicH2 {
  text-align: center;
  margin-top: 120px;
}

.blogSection {
  min-height: 60vh;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.blogContainer {
  border: 2px solid #0d6efd;
  padding: 20px;
  border-radius: 5px;
  margin-top: 100px;
}

@media (max-width: 936px) {
  .mobileMenuBtn {
    visibility: visible;
  }
  nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 75px;
    height: calc(100vh - 75px);
    left: -100%;
    width: 99%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background-color: #f7f7fb;
    z-index: 1;
  }
  nav li {
    width: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  nav a {
    width: 100%;
    text-align: center;
  }
  .navLink:hover, .navLink:focus, .navLink:active {
    background-color: #a85cf9;
    color: #fff;
    border-radius: 25px;
  }
  .navLink:hover::before, .navLink:focus::before, .navLink:active::before {
    visibility: hidden;
  }
  .showMe {
    left: 0;
  }
  .hideMe {
    left: -100%;
  }
  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 20px;
  }
  .footer .socialDiv {
    width: 50%;
  }
  .footer .socialDiv a {
    -ms-flex-item-align: center;
        align-self: center;
  }
  .footer .siteMapDiv {
    margin: 20px 0;
  }
  .footer .newsLetterDiv {
    width: 90%;
  }
  .creditsContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .credits {
    margin-top: 5px;
  }
  .heroSection h2 {
    font-size: 50px;
    line-height: 28px;
    margin: 15px 0;
  }
  .headingContainer {
    margin-top: 45%;
    padding-left: 50px;
  }
  .formSection {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .formSection .imgContainer {
    width: 90%;
  }
  .formSection .formContainer {
    width: 100%;
    margin-top: 25px;
    min-height: 50vh;
  }
  .schematicContainer {
    margin-left: auto;
  }
}
@media (max-width: 850px) {
  .cardContainer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .card,
.comment {
    width: 40%;
    margin: 20px;
  }
  .aboutContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .commentContainer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .schematicBody {
    padding-bottom: 100px;
  }
  .schematicContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .schematic {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 20px 0;
  }
  .schematic .textContainer {
    width: 90%;
  }
  .schematic .imgContainer {
    width: 90%;
  }
  .howToPlayContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .howToPlayContainer .imgContainer,
.howToPlayContainer .howToPlayTextContainer {
    width: 80%;
    margin: 20px 0;
  }
  .instructions .imgContainer {
    width: 30%;
  }
  .gameListContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .gameListContainer li {
    width: 80%;
  }
  .filterContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buildGamesContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .buildGamesContainer .imgContainer {
    width: 80%;
  }
  .buildGames {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 80%;
  }
  .buildGames h3,
.buildGames p {
    width: 80%;
  }
  .formSection .imgContainer {
    margin: 0 auto;
  }
  .formSection .formContainer {
    margin: 25px auto 0 auto;
  }
}
@media (max-width: 600px) {
  .headingContainer {
    padding-left: 0;
  }
  .heroSection h2 {
    line-height: 50px;
  }
  .vizContainer p {
    width: 85%;
  }
  .card,
.comment,
.aboutTextContainer {
    width: 80%;
  }
  .schematicContainer {
    width: 90%;
  }
  .filterList {
    width: 80%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .videoContainer {
    padding: 10px 5px;
  }
  .developerTitleContainer h2 {
    font-size: 30px;
    width: 60%;
  }
  .toolsContainer {
    width: 80%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 50px auto 0 auto;
    padding: 10px;
  }
  .toolsContainer .imgContainer {
    width: 40%;
  }
}